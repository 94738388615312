import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import character1 from "./assets/1.png"
import character2 from "./assets/2.png"
import character3 from "./assets/3.png"
import { useState } from 'react';

function App() {
  const [battle,setBattle] = useState(0);
  return (
   <>

    <div className='row'>
      <div className='col-sm-3'>
        <div className='card text-white fw-bold bgCard' style={{height:"300px"}}>
          <div className='card-header bgHeader'>
            VIKING INFORMATION
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-sm-6'>
                TVL
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>
            <br />

            <div className='row'>
              <div className='col-sm-6'>
                TOTAL INVESTMENT
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>
           <br />
            <div className='row'>
              <div className='col-sm-6'>
                PRICE
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>

          

            <br />


            <div className='row'>
              <div className='col-sm-6'>
                COINS MINTED
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>

            <br />

            <div className='row'>
              <div className='col-sm-6'>
                TOTAL WITHDRAWN
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>

            <br />

            
          

            
          </div>
        </div>
     <br />

        <div className='card text-white fw-bold bgCard' style={{height:"300px"}}>
          <div className='card-header bgHeader'>
            USER INFORMATION
          </div>
          <div className='card-body'>
          <div className='row'>
              <div className='col-sm-6'>
                WALLET
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>

            <br />

            <div className='row'>
              <div className='col-sm-6'>
                TOTAL INVESTED
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>

            <br />

            <div className='row'>
              <div className='col-sm-6'>
                TOTAL COINS
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>
          <br />

          <div className='row'>
              <div className='col-sm-6'>
                TOTAL WITHDRAWN
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>

      <div className='col-sm-6'>
        <h1 className='text-center text-white fw-bold bgHeader'>
          {battle===0? <>FLOKI ZONE</> : battle ===1? <>ROLLO ZONE</> : <>KING RAGNAR ZONE</>}
        </h1>
    <center>    <button className='buttonTag' onClick={()=> setBattle(0)}>FLOKI ZONE</button><button className='buttonTag' onClick={()=> setBattle(1)}>ROLLO ZONE</button><button className='buttonTag' onClick={()=> setBattle(2)}>KING RAGNAR ZONE</button>
    </center>
       <center> 
        {battle===0?<>  <img src={character3} alt="character" width={500} />
       </> : battle ===1? <><img src={character2} alt="character" width={500} /></>: <>
       <img src={character1} alt="character" width={500} />
       </>}
        <br /> 
       <button className='buttonTag'>INVEST</button> <button className='buttonTag2'>REINVEST</button> <button className='buttonTag3'>WITHDRAW</button></center>
    </div>


      <div className='col-sm-3'>
      <div className='card text-white fw-bold bgCard' style={{height:"300px"}}>
          <div className='card-header bgHeader'>
            USER REWARDS
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-sm-6'>
                Daily Reward
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>
            <br />

            <div className='row'>
              <div className='col-sm-6'>
                POWER
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>
         

        

            
          

            
          </div>
        </div>
    <br />

        <div className='card text-white fw-bold bgCard' style={{height:"300px"}}>
          <div className='card-header bgHeader'>
            USER REFERRAL
          </div>
          <div className='card-body'>
          <div className='row'>
              <div className='col-sm-6'>
                REFERRAL REWARD
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>

            <br />

            <div className='row'>
              <div className='col-sm-6'>
                DIRECT REFERRALS
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>

            <br />

            <div className='row'>
              <div className='col-sm-6'>
                IN-DIRECT REFERRALS
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>
          <br />

          <div className='row'>
              <div className='col-sm-6'>
                TOTAL WITHDRAWN
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}>
                0x0000000
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>
    </div>
    <div className='card text-white fw-bold bgCard'>
      <div className='card-body'>
        <center>POWERS</center>
      </div>
    </div>

   </>
  );
}

export default App;
